



.item-gallery-widget .box-shadow.right {
    right: -61px;
    -webkit-box-shadow: -20px 0 30px -20px rgba(0,0,0,.3);
    box-shadow: -20px 0 30px -20px rgba(0,0,0,.3);
    position: absolute;
    z-index: 1;
    top: 20px;
    bottom: 0;
    display: none;
    width: 60px;
    display: block;
    height 100%
}

.item-gallery-widget .box-shadow.left {
    left: -61px;
    -webkit-box-shadow: 20px 0 30px -20px rgba(0,0,0,.3);
    box-shadow: 20px 0 30px -20px rgba(0,0,0,.3);
    position: absolute;
    z-index: 1;
    top: 20px;
    bottom: 0;
    display: none;
    width: 60px;
    display: block;
    height 100%
}
