#section-map {
  overflow hidden
}
#section-map .map-container {
  position: relative;
  width: 100%;
  height: 250px;
  background: #ebecf0;
}
#section-map .map-container #map {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  bottom: -20px;
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  overflow hidden
}
#section-map .map-container .info-container {
  position: absolute;
  top: 0;
  right: 0;
  background: #2B5B37 //#203260;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 30px 30px 0 30px;
}
#section-map .map-container .info-container p:nth-child(1) {
  padding: 0 0 10px;
  font-size: .9375rem;
  color: #C3D2A4 //#608ad8;
  letter-spacing: -.04em;
  font-weight 700
}

#section-map .map-container .info-container p:nth-child(2) {
  color: #C3D2A4 //#7587b6;
  font-size: 1.1rem;
  letter-spacing: -.04em;
}
