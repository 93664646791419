



.cookie-banner {
  display: block;
  border-radius: 5px;
  box-sizing: border-box;
  opacity: 1;
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 9999;
  z-index: 9999999999 !important;

  border-width: 0;
  margin: 1.667em;
  font-size: 14px;
  //color: rgb(255, 255, 255);
  background: #fffde7;
  max-width: 400px;
  max-height: 70vh;
  padding: 15px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.cookie-banner-text {
  max-height: 50vh;
  overflow-y: auto;
}