.event-search-widget {
  background-color: white;
  width: 350px;
  max-width: 100%;
  border-radius: 2px;
}

.event-search-widget .MuiInputBase-input {
  padding: 0;
}
