



.product-feature-select-widget {
    display flex

}

.product-feature-select-widget .select-item {
    padding 2px 10px
    background-color white
    border: 1px solid #eee
    margin-left 10px
    cursor pointer
    border-radius 3px
}


.product-feature-select-widget .select-item:hover {
    border-color: rgba(63, 81, 181, 0.5)
}

.product-feature-select-widget .select-item.selected {
    border-color: rgba(63, 81, 181, 0.5)
    color #3f51b5
}
