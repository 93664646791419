.MuiBadge-colorPrimary {
  background-color: #494089 !important;
}
.MuiButton-textPrimary {
  color: #494089 !important;
}

.button-checkout {
  background-color: #b4bbf5 !important;
}

.MuiBottomNavigationAction-root.Mui-selected {
  color: #666eab !important;
}

.light-theme .app-header-main .app-header-wrapper {
  border-color: #000000;
}

.light-theme .app-header-main {
  background-color: transparent;
  color: #000000;
}

.light-theme .app-header-main .menu-item {
  color: black !important;
  border-color: transparent;
}

.app-header-main .login-button svg {
  color: white;
}

.light-theme .app-header-main .login-button svg {
  color: black;
}
