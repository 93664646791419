.terms-and-conditions,
.terms-and-conditions font {
  font-family: Roboto !important;
  color: black;
}

.terms-and-conditions .western {
  margin-top: 10px;
}

.terms-and-conditions-content h1 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
}

.terms-and-conditions-content h2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.terms-and-conditions-content h3 {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 10px;
}
