@font-face {
  font-family: "Piroshky";
  src: url("/fonts/piroshky-regular.otf");
  font-style: normal;
  font-weight: normal;
}

* {
  //font-family: "piroshky-regular", sans-serif;
}

.mb-20,
.my-20 {
  margin-bottom: 20px !important;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.form-group {
  position: relative;
}

.form-group {
  margin-bottom: 30px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0 20px;
  font-size: 1rem;
  line-height: 1;
  color: #000000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e9f2;
  border-radius: 0.5rem;
  -webkit-transition: all border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out ease;
  transition: all border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out ease;
  transition: all border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out ease;
  transition: all border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out ease, -webkit-box-shadow 0.15s ease-in-out ease;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}
.form-control {
  height: 60px;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiOutlinedInput-root {
  border-radius: 20px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.25) !important;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ee4034 !important;
  border-width: 1px !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #ee4034 !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.fs14 {
  font-size: 14px !important;
}

@media (max-width: 767.98px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 767.98px) and (max-width: 979.98px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 979.98px) and (max-width: 1199.98px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1199.98px) {
  .hidden-lg {
    display: none !important;
  }
}

.structure-list__sortable-object {
  z-index: 1800 !important;
}

.button-action {
  color: #3a60af !important;
  background-color: #ecf2fa !important;
  border-color: #ecf2fa !important;
}

.button-action:hover,
.button-action:active,
.button-action:focus {
  color: #3a60af !important;
  background-color: #c9dbf3 !important;
  border-color: #c9dbf3 !important;
}

.button-blue-border {
  color: #3a60af !important;
  //background-color: #ecf2fa !important;
  border-color: #3a60af !important;
}

.button-blue-border:hover,
.button-blue-border:active,
.button-blue-border:focus {
  color: #3a60af !important;
  background-color: #c9dbf3 !important;
  border-color: #c9dbf3 !important;
}

.button-green {
  color: #416762 !important;
  background-color: #ceeae5 !important;
  border-color: #ceeae5 !important;
}

.button-green:hover,
.button-green:active,
.button-green:focus {
  color: #416762 !important;
  background-color: #b9e2db !important;
  border-color: #b9e2db !important;
}

.button-dark-blue {
  color: #fff !important;
  background-color: #336594 !important;
  border-color: #336594 !important;
}

.button-dark-blue:hover,
.button-dark-blue:active,
.button-dark-blue:focus {
  color: #fff !important;
  background-color: #295278 !important;
  border-color: #295278 !important;
}

.tree-widget .MuiDialogContent-root {
  padding: 0 !important;
}

.tree-widget .MuiList-padding {
  padding: 0 !important;
}

.tree-widget .MuiDialog-paperWidthXs,
.tree-widget .MuiDialog-paperWidthSm,
.tree-widget .MuiDialog-paperWidthMd,
.tree-widget .MuiDialog-paperWidthLg {
  width: 100%;
  height: 100%;
}

.tree-widget .expand-icon {
  transform: translateY(0%) rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tree-widget .expanded .expand-icon {
  transform: translateY(0%) rotate(180deg);
}

.tree-widget .description {
  display: none;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.tree-widget .expanded .description {
  display: flex;
}

.tree-widget .item {
  border-left: 3px solid transparent;
}

.tree-widget .item:hover {
  border-color: #336594;
}

.tmallItem,
.blog-item {
  //border 1px solid #eee
  box-shadow: 0 0px 1px #ccc;
}


#jobs_page {
    background-color: red !important;
}

.react-calendar__tile--active {
    background-color: rgb(212, 69, 79) !important;
}

.grecaptcha-badge { visibility: hidden; }



// Outline visibility
/* Button Focus Styles */
button:focus-visible,
.MuiButtonBase-root:focus-visible,
.MuiIconButton-root:focus-visible {
    outline: 2px solid #007BFF; /* Blue outline for focus */
    outline-offset: 4px;
}

/* Input Focus Styles */
input:focus-visible,
.MuiInputBase-input:focus-visible,
.MuiOutlinedInput-input:focus-visible {
    outline: 2px solid #FF5733; /* Orange outline for input focus */
    outline-offset: 4px;
}

/* Anchor (a) Role Button Focus Styles */
a[role="button"]:focus-visible,
.u-cursorPointer:focus-visible {
    outline: 2px solid #28A745; /* Green outline for anchor focus */
    outline-offset: 4px;
}

/* Anchor with h2 inside Focus Styles */
a:focus-visible h2,
.u-textColorPrimary:focus-visible {
    outline: 2px solid #FFC107; /* Yellow outline for h2 inside anchor focus */
    outline-offset: 4px;
}


