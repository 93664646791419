*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

p {
  margin-bottom: 0;
}

a {
  color: inherit;
}

html {
  height: 100%;
}

body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif, system, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000000;
  text-align: left;
  background-color: #fff;
}

body {
  position: relative;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-content {
  //padding-top: 91px;
  right: 0;
  bottom: 0;
  left: 0;
  //overflow: auto;
}

.app-content.hide-header {
  padding-top: 0 !important;
}

@media (min-width: 768px) {
  .app-content {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@media (max-width: 768px) {
  .app-content {
    padding-top: 60px !important;
  }
  .app-content.hide-header {
    padding-top: 0px !important;
  }
}

/*
.app-content:before {
  display: block;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: -webkit-gradient(linear, left top, left bottom, from(#F9FAFC), to(#fff));
  background: linear-gradient(to bottom, #F9FAFC 0%, #fff 100%);
  height: 280px;
}
*/

.background-wrapper:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: url(/static/img/pattern.png);
    background-repeat: repeat;
    background-size: 250px;
    height: 100%;
    opacity: 0.8;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1100px !important;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1080px;
  }
}

a {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transition-delay: 0;
  transition-delay: 0;
}

a {
  //color: #336594;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

img {
  vertical-align: middle;
  border-style: none;
}

.link {
  cursor: pointer;
  color: #336594 !important;
}
.link:hover {
  color: #1f3e5b !important;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}

.me-action-button {
  padding: 10px 20px 10px 20px;
  border-radius: 3px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: rgba(255, 255, 255, 0.2);
  //font-weight: 700;
  font-weight: 700;
  font-size: 14px;
  color: white;
}

.me-action-button.light {
  background-color: rgba(0, 0, 0, 0.1);
  color: #5e5d6d;
}

.me-action-button:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.me-action-button.light:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.ya-chat-icon g g path {
  fill: #7177a7 !important;
}

@media (max-width: 768px) {
  .ya-chat-widget {
    width: 0px !important;
    height: 0px !important;
    min-width: 0px !important;
  }
  .ya-chat-button {
    display: none !important;
  }
}

.paper-box {
  background: #ffff;
}

.paper-box-outlined {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  //box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.button-primary {
  color: white !important;
  border: none !important;
  border-radius: 25px !important;
  background: #d4454f !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.button-primary:hover {
  background: #b7363f !important;
}

.button-secondary {
  color: #000000 !important;
  border: none !important;
  border-radius: 25px !important;
  background: #faf4a2 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;

  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25) !important;
}

.button-secondary-header {
  color: #ffff !important;
  font-weight: 800 !important;
  border: 1px solid black !important;
  border-radius: 25px !important;
  background: #d4454f !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  transition: transform 10s;
}
.button-secondary-header:hover {
  transform: scale(1.05);
}

.button-secondary:hover {
  background: #faf4a2 !important;
}

.button-grey {
  color: #000000 !important;
  border: none !important;
  border-radius: 25px !important;
  background: #eee !important;
  padding-left: 15px !important;
  padding-right: 15px !important;

  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25) !important;
}

.button-grey:hover {
  background: #e2e2e2 !important;
}

.button-disabled {
  border: none !important;
  border-radius: 25px !important;
  background: #eee !important;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25) !important;
}

.u-textColorPrimary {
  color: #d4454f
}

.u-textColorSecondary {
  color: #faf4a2;
}

.flat-tabs {
  width: 100%;
  max-height: 48px;
}

.flat-tabs button {
  min-width: 1px;
  padding: 0;
  margin-right: 20px;
}

.flat-tabs button:disabled {
  font-size: 24px;
  line-height: 24px;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.74);
  opacity: 1 !important;
}

.flat-tabs button > span align-items left > span {
  padding-left: 0;
  padding-right: 0;
  opacity: 0.7;
}

.flat-tabs > div > div > span {
  background-color: white;
}

.flat-tabs > div:nth-child(2) > div:nth-child(1),
.flat-tabs > div:nth-child(2) > div:nth-child(3),
.flat-tabs > div:nth-child(2) > button {
  display: none;
}

.image-widget-item {
  background: no-repeat 50%;
  background-size: cover;
  border-radius: 3px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
  vertical-align: top;
  display: inline-block;
}

.cart-dialog .cart-header {
  background-color: #ee4034;
  color: white;
}


.atcb_list {
  z-index: 9995 !important;
}

.atcb_bgoverlay {
  z-index: 9990 !important;
}


input[type="number"] {
  -moz-appearance: textfield; /* Hides the arrows */
}

/* For Chrome, Edge, and Safari */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Hides the arrows */
}