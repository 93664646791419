.app-header-main {
  background-color: #faf4a2;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.app-header-main .app-header-wrapper {
  width: 100%;
  height: 65px;
  max-width: 1390px;
  margin: 0px auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .app-header-main .app-header-wrapper {
    height: 50px;
    overflow: hidden;
    border-bottom: 0px;
  }
}

.app-header-main .menu-item {
  cursor: pointer;
  height: 65px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  text-decoration: none !important;
  padding: 0 5px;
  margin: 0 10px;
  color: #000000;
  font-weight: 400;
  text-align: center;
}

.app-header-main .menu-item:hover {
  color: #212121;
  border-bottom: 2px solid #e02b1f;
}

.app-header-main .menu-item.active {
  border-bottom: 2px solid #e02b1f;
  color: #212121;
}

@media (max-width: 767px) {
  .app-header-main #account,
  .app-header-main .app-header-menu {
    display: none !important;
  }

  .app-header-main .icon-menu {
    display: block !important;
  }
}

.category-button .MuiBadge-badge {
  top: 50%;
  right: -3px;
  border: 2px solid #eeeeee;
}

.app-header-main .button-login {
  padding: 10px 16px;
  border-radius: 4px;
  color: #171355;
  font-size: 15px;
  height: 40px;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.app-header-main .button-login.dark {
  background-color: rgba(23, 19, 85, 0.1);
}

.app-header-main .button-login.dark:hover {
  background-color: rgba(23, 19, 85, 0.2);
}
