


.delivery-step .delivery-option {
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    height 50px
    display flex
    justify-content center
    align-items center
    padding 0 10px
    font-size 14px
    max-width 180px
    text-align center
}

.delivery-step .delivery-option.selected {
    color white
    background-color rgba(238, 64, 52, 1)
}


@media (max-width: 767px) {
  .delivery-step .delivery-option {
      height 40px
      max-width 200px
      margin-top 5px
      margin-bottom 5px
      width 100%
  }
}
