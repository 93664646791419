.account-widget-menu {
  .MuiList-root {
    padding: 0;
    min-width: 240px;
  }
  .MuiMenuItem-root {
    border-top: 1px solid #eee;
  }
}
#account {
  position: relative;
  margin-right: -10px;
  .account-avatar {
    cursor: pointer;
    padding: 10px 10px;
  }
  &:hover {
    .account-overlay {
      display: block;
    }
  }
  .account-overlay {
    display: none;
    position: absolute;
    right: 0;
    top: 55px;
    min-width: 270px;
    padding: 15px;
    padding-top: 0;
  }
  .account-container {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 5px 0 rgba(132, 146, 166, 0.6);
    box-shadow: 0 1px 5px 0 rgba(132, 146, 166, 0.6);
    border-radius: 5px;
  }
  .account-menu {
    margin: 0;
    padding: 20px 0 16px 0;
    div {
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      padding: 10px 30px 10px 27px;
      border-left: 3px solid transparent;
      font-weight: 400;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      -webkit-transition-delay: 0;
      transition-delay: 0;
      color: #336594;
      svg {
        font-size: 22px;
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        color: #8492a6;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        -webkit-transition-delay: 0;
        transition-delay: 0;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        color: #5a6679;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        -webkit-transition-delay: 0;
        transition-delay: 0;
      }
    }
  }
}
.mobile {
  #account {
    .account-avatar {
      padding: 0;
      div {
        width: 35px;
        height: 35px;
      }
    }
  }
}
.account-mobile-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
}
#account .account-menu div:hover,
#account .account-menu div.active {
  border-color: #336594;
}
#account .account-menu div:hover svg,
#account .account-menu div.active svg {
  color: #336594;
}
#account .account-menu div:hover span,
#account .account-menu div.active span {
  color: #000000;
}
