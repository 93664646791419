#lightbox-validation
{
  display: none;
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: -webkit-gradient(linear, left top, right top, from(#fbee9d), to(#f7e886));
  background: -webkit-linear-gradient(left, #fbee9d 0%, #f7e886 100%);
  background: -o-linear-gradient(left, #fbee9d 0%, #f7e886 100%);
  background: linear-gradient(90deg, #fbee9d 0%, #f7e886 100%);
  opacity: 0;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
#lightbox-validation.show
{
  display: block;
  opacity: 1;
}
#lightbox-validation .content
{
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align:center;
}
#lightbox-validation .content img1
{
  width: 300px;
  display: block;
  margin: auto;
  margin-bottom: 50px;
  opacity: 0;
  -webkit-transform: translate(50%, 50%);
  -ms-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
  -webkit-transition: all 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
  -o-transition: all 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
  transition: all 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
}
#lightbox-validation .content.show img1
{
  opacity: 1;
  -webkit-transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}
#lightbox-validation .content .container-text-box
{
  position:relative;
  text-align:center;
}
#lightbox-validation .content .container-text-box .line
{
  color: #ee4034;
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  overflow:hidden;
}
#lightbox-validation .content .container-text-box .line.sub
{
  color: #ee4034;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  overflow:hidden;
}
#lightbox-validation .content .container-text-box .line:nth-child(2)
{
  opacity: 0.6;
}
#lightbox-validation .content .container-text-box .line span
{
  display: block;
  -webkit-transform: translateY(100%) rotate(5deg);
  -ms-transform: translateY(100%) rotate(5deg);
  transform: translateY(100%) rotate(5deg);
  -webkit-transition: transform 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
  -o-transition: transform 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
  -webkit-transition: -webkit-transform 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
  transition: -webkit-transform 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
  transition: transform 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
  transition: transform 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005), -webkit-transform 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
}
#lightbox-validation .content .container-text-box .line:nth-child(1) span
{
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
#lightbox-validation .content .container-text-box .line:nth-child(2) span
{
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
#lightbox-validation .content.show .container-text-box .line span
{
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}
#lightbox-validation .content .btn
{
  display: inline-block;
  margin-top: 45px;
  padding: 18px 50px 18px 50px;
  border-radius: 3px;
  background-color: #FFFFFF;
  text-decoration: none;
  background-color: rgba(241, 102, 71, 0.2);
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  -webkit-transition: all 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
  -o-transition: all 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
  transition: all 0.75s cubic-bezier(0.770, 0.000, 0.230, 1.005);
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
#lightbox-validation .content.show .btn
{
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}


#lightbox-validation .content .btn p
{
  color: #ee4034;
  font-weight: 700;
  font-size: 14px;
}



@media screen and (max-width: 500px) {

  #lightbox-validation .content img
  {
    width: 150px;
  }
  #lightbox-validation .content .container-text-box .line
  {
    font-size: 30px;
    line-height: 35px;
  }

}
