.footer {
  color: #000000;
  position: relative;
  padding: 55px 0px 55px 0px;
  background-color: #faf4a2;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .footer .app-header-menu {
    flex-direction: column;
  }
  .footer .app-header-menu .menu-item {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }
  .footer .copyright {
    text-align: center;
  }
}
