.event-delivery-form .calendar-widget {
  background-color: #fef19f;
  padding-top: 30px;
  padding-bottom: 30px;
}

.event-delivery-form .calendar-widget img {
  max-width: 300px;
}

.event-delivery-form .react-calendar {
  border: none;
}

.event-delivery-form .react-calendar__tile--active,
.event-delivery-form .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ee4034 !important;
}

.event-delivery-form .react-calendar__tile--now {
  background: #f7e886;
}

.event-delivery-form .react-calendar__tile:disabled {
  background-color: transparent;
  opacity: 0.3;
}

.event-delivery-form .event-delivery-form .MuiListItem-root {
  border: none;
  background-color: white;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.events-map {
  width: 100%;
}