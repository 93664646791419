
.product-description-block .product-name-vertical h1 {
    font-size 25px
}

.product-description-block .product-name-vertical .product-name-content {
    margin-top: 10px
    margin-bottom 10px
}

.product-description-block .product-name-vertical .product_property {
    font-size 15px
}

.product-description
    padding-right 15px
.product-description table td {
    padding: 8px 20px;
    border: 1px solid #b5bdc6;
}

.product-description table td p:last-child {
    margin-bottom: 0;
}

.product-description table td p {
    margin: 0 0 8px 0;
}


.product-description table {
    margin: 30px 0;
}

.product-description p {
    margin: 0 0 10px;
}


.product-description h2, .product-description .h2 {
    font-size: 18px !important;
    line-height: 24px !important;
    font-weight: normal;
    color: #1e5ca5;
    margin: 15px 0 10px 0 !important;
    text-transform: none;
}


.product-description h3, .product-description .h3 {
    font-size: 16px !important;
}

.product-description ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.product-description li {
    display: list-item;
    text-align: -webkit-match-parent;
}

@media screen and (max-width: 767px) {
    .product-description {
        padding 0
    }
}


.product-description-block .item {
    border-color: transparent !important
}
