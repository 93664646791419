


.product-image-widget .item-preview {
    width 50px
    height 50px
    opacity 0.75
    transition opacity 0.5s
}

.product-image-widget .item-preview.selected {
    opacity 1
    box-shadow rgba(38,78,118,0.2) 0px 0px 15px inset
}

.product-image-widget .item-preview:hover {
    opacity 1
}



.react-images__blanket {
    z-index 2001 !important
}

.react-images__positioner {
    z-index 2002 !important
}
